import * as R from 'ramda'
import React from 'react'
import styled, { ThemeProvider } from 'styled-components'
import { Link, graphql } from 'gatsby'

import Layout, { extractData, THEMES } from '../components/layout'
import { Page, Routing, SSRAware, MovieVM, posterWidthAndHeight, List, LinkItem } from '../components/utils'
import { Movie, Cinema, CinemaId, Days, Day, Model, SpecialEvent } from '../model/model'

import Section, { SubSection } from '../components/section';
import { renderTruncatedDescription } from '../components/movie-summary';
import { TaggedPoster } from '../components/tagged-poster';
import createStore from '../model/create-store'
import { Provider, connect } from 'react-redux';
import ConnectedUpdater from '../components/updater';
import RImage from '../components/image';
import { Dispatch } from 'redux';
import { Action, Actions } from '../model/actions';
import { Tracking, assertNever, isNotNull } from '../model/utils';

const shortDescription = renderTruncatedDescription(25, 0, false)
const longDescription = renderTruncatedDescription(55, 0, false)

const Card = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  @media (min-width: 700px) {
    padding: 5px 10px;
  }
`
const Poster = styled(RImage)`
  display: flex;
  ${posterWidthAndHeight(120)}
  object-fit: cover;
  @media (min-width: 700px) {
    ${posterWidthAndHeight(130)}
  }
`
const Title = styled.h3`
  display: flex;
  text-transform: uppercase;
  font-size: 1rem;
  margin: 0;
  font-weight: 300;
  @media (min-width: 700px) {
    line-height: 1.3rem;
  }
`
const SubTitle = styled.span`
  display: flex;
  color: ${props => props.theme.colors.attenuate};
  font-size: 0.8rem;
  line-height: 1rem;
  padding-top: 0.1rem;
`
const Text = styled.div`
  display: flex;
  overflow: hidden;
  height: 90px;
  font-size: 0.8rem;
  line-height: 1.1rem;
  margin: 0;
  flex-grow: 1;
  @media (min-width: 700px) {
    font-size: 1rem;
    line-height: 1.3rem;
  }
`

const MobileText = styled(Text)`
  display: flex;
  @media (min-width: 700px) {
    display: none;
  }
`

const DesktopText = styled(Text)`
  display: none;
  @media (min-width: 700px) {
    display: flex;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 5px;
  width: 100%;
`

interface MovieCardProps {
  movie: Movie
  mainCinemaId: CinemaId
  cinemas: Cinema[]
}

const hasShows = (mainCinemaId: CinemaId, events: Movie[]): boolean => {
  return R.any(event => {
    return event.event != null && event.event[mainCinemaId] != null
      && event.event[mainCinemaId]!.dates.length > 0
  }, events)
}
const sortTypes = (mainCinemaId: CinemaId, sections: {[index: string]: Movie[]}) => (a: string, b: string): number => {
  const aShows = hasShows(mainCinemaId, sections[a])
  const bShows = hasShows(mainCinemaId, sections[b])
  return aShows
    ? bShows
      ? a.toLowerCase().localeCompare(b.toLowerCase())
      : -1
    : 1
}

const getEventFirstDay = (e: SpecialEvent | null): Day | null => {
  if (e == null) {
    return null
  }
  const firstMds = e.mds == null
    ? null
    : e.mds.dates.length == 0
      ? null
      : e.mds.dates[0]
  const firstOdeon6 = e.odeon6 == null
    ? null
    : e.odeon6.dates.length == 0
      ? null
      : e.odeon6.dates[0]
  return Days.getEarliest([firstMds, firstOdeon6])
}

const sortEvents = (a: Movie, b: Movie): number => {
  const aStart = getEventFirstDay(a.event)
  const bStart = getEventFirstDay(b.event)
  return Days.difference(aStart, bStart)
}

const MovieCard: React.SFC<MovieCardProps & SSRAware> = ({isSSR, movie, cinemas, mainCinemaId}) => (
  <Card>
    <TaggedPoster tags={MovieVM.getTagsForEventElsewhere(cinemas, movie)} fontSize={0.55}>
      <Poster mode={isSSR ? 'stub' : 'lazy'} image={movie.poster} alt={`Poster film ${movie.title}`}
        sizes={[{viewport: 700, size: 120}]}
        maxSize={150} />
    </TaggedPoster>
    <Content>
      <Title>{movie.title}</Title>
      <SubTitle>{ MovieVM.getReleaseMessage(movie, mainCinemaId) }</SubTitle>
      <MobileText>{shortDescription(movie)}</MobileText>
      <DesktopText>{longDescription(movie)}</DesktopText>
    </Content>
  </Card>
)

const Heading = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`
const Header = styled.span`
  margin-right: 10px;
`

interface EventsSection {
  name: string
  events: Movie[]
}
interface PageIntents {
  onSortByType: () => void
  onSortByDate: () => void
}
interface StaticProps {
  mainCinemaId: CinemaId
  cinemas: Cinema[]
}
interface DynamicProps {
  sections: EventsSection[]
  selectedSort: 'TYPE' | 'DATE'
}
type PageProps = StaticProps & DynamicProps

const EventsPage: React.SFC<PageProps & SSRAware & PageIntents> = ({onSortByType, onSortByDate, isSSR, cinemas, mainCinemaId, sections, selectedSort}) => {
  return (
    <Layout showBackButton={false} cinemas={cinemas} title="Eventi">
      <Page>
        <Section title='Eventi'>
        <Heading>
          <Header>Raggruppa per:</Header>
          <List wrap='nowrap'>
            <LinkItem state={selectedSort === 'TYPE' ? 'selected' : 'normal'}
              onClick={(e) => {
                Tracking.trackEngagement('events/sort-events', 'by-type')
                onSortByType()
              }}>
              Tipo di Evento
            </LinkItem>
            <LinkItem state={selectedSort === 'DATE' ? 'selected' : 'normal'}
              onClick={(e) => {
                Tracking.trackEngagement('events/sort-events', 'by-date')
                onSortByDate()
              }}>
              Data
            </LinkItem>
          </List>
        </Heading>
        { sections.map(section =>
          <SubSection key={section.name} title={section.name}>
            { section.events.map (m =>
              <Link key={m.id} to={Routing.toMoviePage(m)}
                onClick={() => {Tracking.trackNavigation('events/main', m.title)}}>
                <MovieCard cinemas={cinemas} isSSR={isSSR} movie={m} mainCinemaId={mainCinemaId}/>
              </Link>
            )}
          </SubSection>
        )}
        </Section>
      </Page>
    </Layout>
  )
}

const mapModelToProps = (model: Model, ownProps: StaticProps): DynamicProps => {
  if (model.page.id !== 'events') {
    throw new Error('Unexpected state when computing Events props: ' + model.page.id)
  }

  var sections: EventsSection[] = []
  switch(model.page.sortBy) {
    case 'TYPE':
      const types = R.groupBy( m => m.event!.type, model.events)
      sections = R.keys(types)
        .sort(sortTypes(ownProps.mainCinemaId, types))
        .map(t => ({
          name: t.toString(),
          events: types[t].sort(sortEvents)
        }))
      break
    case 'DATE':
      const groupedEvents = R.groupBy(e => (e.releaseDate != null
        ? Days.toString(e.releaseDate)
        : 'Prossimamente'
      ), model.events)
      const days = R.keys(groupedEvents).filter(k => k != 'Prossimamente')
      sections = days
        .map(Days.fromString)
        .sort(Days.difference)
        .map(Days.toString)
        .map(day => {
          return {
            name: day,
            events: groupedEvents[day]
          }
        })
      isNotNull(groupedEvents['Prossimamente']) && sections.push({
        name: 'Prossimamente',
        events: groupedEvents['Prossimamente']
      })
      break
    default:
      assertNever(model.page.sortBy)
  }

  const props = {
    selectedSort: model.page.sortBy,
    sections: sections
  }

  return props
}

const mapDispatchToIntents = (dispatch: Dispatch<Action>) => ({
  onSortByType: () => {
    dispatch(Actions.selectSort('TYPE'))
  },
  onSortByDate: () => {
    dispatch(Actions.selectSort('DATE'))
  }
})

const ConnectedPage = connect(
  mapModelToProps,
  mapDispatchToIntents
)(EventsPage as any)

const WrappedPage: React.SFC<any> = ( query ) => {
  const { mainCinemaId, cinemas } = extractData(query)
  const store = createStore('events', cinemas, mainCinemaId, false)

  return (
    <ThemeProvider theme={THEMES[mainCinemaId]}>
      <Provider store={store}>
        <>
          <ConnectedUpdater/>
          <ConnectedPage cinemas={cinemas} mainCinemaId={mainCinemaId} />
        </>
      </Provider>
    </ThemeProvider>
  )
}

export default WrappedPage

export const query = graphql`
query EventiPageQuery {
  ...alldata
}
`
