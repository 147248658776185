import React from 'react'
import { Movie, CinemaId } from "../model/model"
import styled from "styled-components"
import { Column, Row, Routing } from "./utils"
import { Link } from '@reach/router';
import { isNotEmpty, isEmpty, lintStringList, replaceAll } from '../model/utils';
import RImage from './image';

const Main = styled(Row)`
width: 100%;
margin-bottom: 10px;
`

const Container = styled.div`
  flex-direction: column;
  margin: 0 5px;
`

const PosterColumn = styled(Column)`
width: 45%;
padding: 0 15px 15px 15px;
align-items: flex-end;
float: left;
`

const DescrColumn = styled(Column)`
width: 65%;
padding: 15px 15px 15px 5px;
text-size: 0.9rem;
`

const Info = styled.div`
margin-top: 1rem;
text-align: right;
width: 100%;
`

const InfoTitle = styled.div`
`

const InfoContent = styled.div`
color: ${props => props.theme.colors.attenuate};
`

const Poster = styled(RImage)`
object-fit: contain;
width: 100%;
min-height: 150px;
`

const MovieTitle = styled.h1`
  font-size: 1rem;
  color: ${props => props.theme.colors.highlight};
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 0.2rem;
`

const MovieSubtitle = styled.div`
  font-size: 0.8rem;
  color: ${props => props.theme.colors.attenuate};
  text-align: center;
  text-transform: capitalize;
  margin-bottom: 0.4rem;
`

const VMWarning = styled.span`
  color: red;
  &::before {
    color: ${props => props.theme.colors.attenuate};
    content: ' | ';
  }
`

export const createSubtitle = (movie: Movie): string => {
  return [
    movie.category.join(' ').toLowerCase(),
    movie.duration == null ? '' : movie.duration + "'"
  ].filter(isNotEmpty).join(' | ')
}

const DateSummaryInDescription = styled.p`
  display: none;
  @media(min-width: 700px) {
    display: block;
  }
`

const TicketInfoInDescription = styled.p`
`

export const defaultRenderDescription = (mainCinemaId: CinemaId, movie: Movie) => {
  const text = movie.description.split('<br>')
    .filter(p => p.trim().length > 0)
    .map((paragraph, index) => (
      <p key={index}>{paragraph.trim()}</p>
  ))
  const isEvent = movie.event != null && !isEmpty(movie.event.type) && movie.event![mainCinemaId] != null
  const dateSummary = isEvent && !isEmpty(movie.event![mainCinemaId]!.dateSummary)
    ? <DateSummaryInDescription key='summary'>{movie.event![mainCinemaId]!.dateSummary}</DateSummaryInDescription>
    : null
  const ticketInfo = isEvent && lintStringList(movie.event![mainCinemaId]!.tickets).length > 0
    ? <TicketInfoInDescription key='tickets'>{lintStringList(movie.event![mainCinemaId]!.tickets).join(' - ')}</TicketInfoInDescription>
    : null
  return [
    dateSummary,
    ticketInfo,
    ...text
  ]
}

const DescriptionLink = styled(Link)`
  text-decoration: underline;
  font-size: 0.8em;
  font-style: italic;
  margin-left: 1em;
`
export const renderTruncatedDescription = (maxSize: number, buffer: number, withLink: boolean) => (m: Movie) : JSX.Element => {
  const tokens = m.description.split(' ')
    .map(p => p.trim())
    .filter(p => p.length > 0)

  const res = tokens.length > maxSize + buffer
    ? <div>
        {
          tokens.slice(0, maxSize).join(' ')
            .split('<br>')
            .map((p, idx, array) => (
              <span key={idx}>{p.trim()} {idx === array.length-1 ? '...' : <br/>}</span>
            ))
        }
        { withLink
        ? <DescriptionLink to={Routing.toMoviePage(m)}>continua</DescriptionLink>
        : null
        }
      </div>
    : <div>{replaceAll('<br>', '-')(m.description)}</div>
  return res
}


interface MovieSummaryProps {
  mainCinemaId: CinemaId
  movie: Movie
  includeTitle?: boolean
  renderDescription?: (movie: Movie) => JSX.Element
}

const SummaryContainer = styled.div`
  display: block;
  width: 100%;
`
const PosterContainer = styled.div`
  display: block;
  width: 100%;
  height: 100%;
`

const MovieSummary: React.SFC<MovieSummaryProps> = ({mainCinemaId, movie, includeTitle=true, renderDescription}) => (
  <Container>
    { !includeTitle
    ? null
    : <div>
        <Link to={Routing.toMoviePage(movie)}>
          <MovieTitle>{movie.title}</MovieTitle>
        </Link>
        <MovieSubtitle>
          {createSubtitle(movie)}
          {
          movie.isVM18
            ? <VMWarning>VM18</VMWarning>
            : movie.isVM14
              ? <VMWarning>VM14</VMWarning>
              : null
          }
        </MovieSubtitle>

      </div>
    }
    <Main>
      <SummaryContainer>
        <PosterColumn>
          <PosterContainer>
            <Link to={Routing.toMoviePage(movie)}>
              <Poster mode='eager' image={movie.poster} alt={`Poster film ${movie.title}`}
                sizes={[{viewport: 700, size: 130}]}
                maxSize={400} />
            </Link>
          </PosterContainer>
          { movie.directors.length == 0
            ? null
            : <Info>
                <InfoTitle>Regia</InfoTitle>
                <InfoContent>{movie.directors.join(', ').toLowerCase().replace(/\b\w/g, l => l.toUpperCase())}</InfoContent>
              </Info>
          }
          { movie.cast.length == 0
            ? null
            : <Info>
                <InfoTitle>Cast</InfoTitle>
                <InfoContent>{movie.cast.join(', ').toLowerCase().replace(/\b\w/g, l => l.toUpperCase())}</InfoContent>
              </Info>
          }
        </PosterColumn>
        { renderDescription == null
          ? defaultRenderDescription(mainCinemaId, movie)
          : renderDescription(movie)
        }
      </SummaryContainer>
    </Main>
  </Container>
)

export default MovieSummary