import React from 'react'
import { Model } from "../model/model"
import { connect } from "react-redux"
import styled from "styled-components"
import Spinner from './spinner';
import { fade } from './utils';


const quotes = [
  { quote: "Francamente me ne infischio.", author: "Rhett Butler", film: "Via col vento" },
  { quote: "Gli farò un'offerta che non può rifiutare.", author: "Don Vito Corleone", film: "Il Padrino" },
  { quote: "Non può piovere per sempre.", author: "Eric", film: "Il Corvo" },
  { quote: "Che la forza sia con te.", author: "Yoda", film: "Star Wars" },
  { quote: "E.T. Telefono Casa.", author: "E.T.", film: "E.T" },
  { quote: "Agitato, non mescolato.", author: "James Bond", film: "007" },
  { quote: "Mi hai conosciuto in un momento molto strano della mia vita.", author: "Il narratore", film: "Fight Club" },
  { quote: "Ho visto cose che voi umani non potete immaginare.", author: "Roy", film: "Blade Runner" },
  { quote: "Sei solo chiacchiere e distintivo!", author: "Al Capone", film: "Gli Intoccabili" },
  { quote: "Se il dito indica il cielo l'imbecille guarda il dito.", author: "Bambino", film: "Il Favoloso Mondo di Amelie" },
  { quote: "Questa è Sparta!", author: "Leonida", film: "300" },
  { quote: "A volte sei tu che mangi l'orso e a volte è l'orso che mangia te.", author: "Cowboy", film: "Il Grande Lebowski" },
  { quote: "Sono il Signor Wolf, risolvo problemi.", author: "Mr. Wolf", film: "Pulp Fiction" },
  { quote: "Un fiorino!", author: "Doganiere", film: "Non ci resta che piangere" }
]

const UpdaterOverlay = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100vw;
  height: 100%;
  background-color: rgba(0,0,0,0.85);
  left: 0;
  z-index: 10;
  top: 0;
  transition: 200ms top;
  animation: ${fade} 0.2s linear;
`

const Title = styled.h1`
  font-size: 1.2rem;
  text-transform: uppercase;
  color: ${props => props.theme.colors.attenuate};
`
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80vw;
  @media(min-width: 700px) {
    width: 60vw;
  }
`

const Bubble = styled.div`
  background-color: #c3a900;
  width: 100%;
  color: white;
  font-size: 1.3rem;
  line-height: 1.6rem;
  padding: 15px;
`

const Arrow = styled.div`
  display: flex;
  width: 10px;
  height: 10px;
  margin-left: 150px;
  margin-top: -5px;
  content: '';
  border-color: #c3a900 transparent transparent transparent;
  border-style: solid;
  border-width: 21px 0px 0px 32px;
  height: 0;
  left: 700px;
  line-height: 0;
`

const Quote = styled.div`
  display: flex;
  width: 90%;
  justify-content: flex-end;
  align-self: flex-end;
`

const Author = styled.span`
  color: ${props => props.theme.colors.attenuate};
`

const Film = styled.span`
  color: white;
  padding-left: 3px;
`

interface UpdaterProps {
  isUpdating: boolean
}
export const Updater: React.SFC<UpdaterProps> = ({isUpdating}) => {
  const quote = quotes[Math.floor(Math.random() * Math.floor(quotes.length))]
  return (isUpdating
    ? <UpdaterOverlay>
        <Container>
          <Title>Aggiornamento in corso</Title>
          <Spinner />
        </Container>
        <Container>
          <Bubble>
          {quote.quote}
          </Bubble>
          <Arrow/>
          <Quote>
            <Author>{quote.author},</Author>
            <Film>{quote.film}</Film>
          </Quote>
        </Container>
      </UpdaterOverlay>
    : null
  )
}

const mapModelToProps: (model: Model) => UpdaterProps = (model) => {
  return {
    isUpdating: model.isUpdating
  }
}

const ConnectedUpdater = connect(
  mapModelToProps
)(Updater as any)

export default ConnectedUpdater