import React from 'react'
import styled from "styled-components"

const TextTag = styled.span<{bgColor?: string, textColor?: string}>`
background-color: ${props => props.bgColor || props.theme.colors.highlight};
color: ${props => props.textColor || 'white'};
box-shadow: 0px 0px 5px 1px black;
white-space: nowrap;
text-overflow: ellipsis;
max-width: 90%;
overflow: hidden;
line-height: 1rem;
padding: 0 3px;
margin: 0 0 5px 0;
`

const Tags = styled.div<{fontSize?: number}>`
display: flex;
position: absolute;
right: 0;
top: 0;
width: 90%;
margin-top: 2%;
font-size: ${props => props.fontSize || 0.7}rem;
line-height: ${props => props.fontSize || 0.7}rem;
padding: 0.1rem 0;
text-transform: uppercase;
flex-direction: column;
align-items: flex-end;
`

const Tag: React.SFC<{tag: string}> = ({tag}) => {
  if (tag.toLowerCase().startsWith('da ')) {
    return <TextTag bgColor='red'>{tag}</TextTag>
  }
  switch(tag.toLowerCase()) {
    case 'mds':
      return <TextTag bgColor='red'>Solo al Multiplex</TextTag>
    case 'odeon6':
      return <TextTag bgColor='red'>Solo all'Odeon 6</TextTag>
    case 'cinema in lingua':
      return <TextTag bgColor='rgb(76, 157, 32)'>{tag}</TextTag>
    case '3d':
      return <TextTag bgColor='rgb(0, 95, 163)'>Anche in 3D</TextTag>
    case '2€':
        return <TextTag textColor='rgb(3, 3, 3)'>2 €uro</TextTag>
    case 'vm14':
    case 'vm18':
      return <TextTag bgColor='red'>{tag}</TextTag>
    default:
      return <TextTag bgColor='rgb(0, 95, 163)'>{tag}</TextTag>
  }
}

interface TaggedPosterProps {
  tags: string[]
  fontSize?: number
}
export const TaggedPoster: React.SFC<TaggedPosterProps> = ({tags, fontSize, children}) => {
  return (
    <div style={{position: 'relative', flexShrink: 0}}>
      {children}
      { tags.length == 0
      ? null
      : <Tags fontSize={fontSize}>
          {tags.map (tag => <Tag key={tag} tag={tag} /> )}
        </Tags>
      }
    </div>
  )
}
